<template>
    <div class="addition-page">
        <section class="page-kv p-0">
            <div class="kv-img ratio-kv">
                <!-- 桌機圖 -->
                <img :src="activity.bannerPc" class="w-100 d-none d-md-inline-block" v-if="activity.bannerPc != ''">
                <img src="@/assets/images/kv_herobanner-1.jpg" class="w-100 d-none d-md-inline-block" v-else>
                <!-- 手機圖 -->
                <img :src="activity.bannerMobile" class="w-100 d-inline-block d-md-none" v-if="activity.bannerMobile != ''">
                <img src="@/assets/images/kv_herobanner-1-sm.jpg" class="w-100 d-inline-block d-md-none" v-else>
            </div>
            <hgroup class="kv-heading">
                <h1>{{ activity.bannerWording }}</h1>
            </hgroup>

        </section>
        <section class="about-block static-section-block">
            <div class="container">
                <div class="row about-block pt-3" v-if="width > 767">
                    <div class="static-content">
                        <div class="col-12" v-html="activity.contentPc"></div>
                    </div>
                </div>
                <div class="row about-block pt-3" v-else>
                    <div class="static-content">
                        <div class="col-12" v-html="activity.contentMobile"></div>
                    </div>
                </div>
            </div>
        </section>
        <section class="products-section bg-light" v-if="itemList.length > 0">
            <div class="container">
                <hgroup class="d-flex justify-content-center">
                    <h2 class="section-title mb-4">
                        <IconStar class="me-2"/>
                        {{ activity.recommendTitle }}
                    </h2>
                </hgroup>
                <div class="row g-2 g-lg-4">
                    <div class="col-12 col-md-4 col-lg-3" v-for="item in itemList">
                        <HouseCard
                            :siteName=activity.siteName
                            :about=activity
                            :item=item
                        ></HouseCard>
                    </div>
                </div>
                <nav aria-label="Page navigation" v-html="paginate"></nav>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import HouseCard from '@/components/HouseCard.vue'
import IconPin from '@/components/icons/IconPin.vue'
import IconStar from '@/components/icons/IconStar.vue'

export default {
    name: 'Activity',
    components: {
        HouseCard,
        IconPin,
        IconStar,
    },
    data() {
        return {
            response: {},
            width: 0,
            activity: {
                bannerPc: '',
                bannerMobile: '',
            },
            aboutImage: {
                aboutBannerPc: '',
                aboutBannerMobile: '',
            },
            itemList: [],
            page: 1,
            currentPage: 1,
            pageCount: 16,
            paginate: '',
            about: {},
            meta: {},
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        }
    },
    watch: {
        id: async function (val) {
            this.response = await this.fetchActivity(val);
            this.render();
        },
    },
    methods: {
        async fetchActivity(id) {
            return await fetch("/api/activities/" + id)
                .then((response) => response.json())
                .then((json) => json);
        },
        render: function () {
            this.width = $(window).width();
            console.log(this.width)
            this.activity = this.response.activity;
            this.meta = this.response.meta;
            document.title = this.activity.activityName + '-' + this.activity.siteName;
            $("meta[property='og:title']").attr("content", this.activity.activityName + '-' + this.activity.siteName);
            document.getElementsByTagName('meta')["description"].content = this.activity.activityName + this.activity.siteName;
            $("meta[property='og:description']").attr("content", this.activity.activityName + this.activity.siteName);
            this.getItemList()
        },
        getItemList: function () {
            this.currentPage = this.page;
            if (this.width > 767) {
                this.pageCount = 16;
            } else {
                this.pageCount = 8;
            }
            let param = {
                params: {
                    page: this.page,
                    pageCount: this.pageCount,
                }
            }
            axios.get('/api/activity/' + this.activity.id + '/items', param)
                .then(response => (
                    this.itemList = response.data.itemList,
                        this.paginate = response.data.paginate
                ))
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    },
    async beforeRouteUpdate(to, from) {
        console.log(to, from)
    },
    async created() {
        this.response = await this.fetchActivity(this.id);
        this.render()
    },
    mounted() {
        let house = this;
        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });

        $("body").on("click", ".page-link", function (event) {
            house.page = $(this).attr('page');
            house.getItemList()
        });
    }
}
</script>
